export const SHIPPING_COSTS = {
    STANDARD_SHIPPING: 1000,
    sku: 'sku_GkDLW0fZ7A6TcI'
}

export const PRODUCT_TYPES = {
    BEAUTY: 'beauty',
    MOISTURISER: 'moisturiser',
    HOUSEHOLD: 'household',
    HEALTH: 'health',
    LAVENDER: 'lavender',
    ROSE: 'rose',
    EUCALYTPUS: 'eucalyptus',
    PET: 'pet',
    BEST_SELLER: 'popular',
    EDIBLE: 'edible',
    ALL: 'all'
}

