import React from "react"
import { Link } from 'gatsby';
import styles from './item.module.css'
import {getFormattedPrice} from "../utils/numberFormatting";

function Item({itemName, itemPrice, imageSource, id}) {
    const formattedPrice = getFormattedPrice(itemPrice);

    return (
        <Link
            to={`/products/${id}`}
            className={`${styles.itemContainer} ${styles.wiggle}`}
        >
            <img alt={`Photograph of an ${itemName} product`} src={imageSource} />
            <section>
                <h3>{itemName}</h3>
                <p>{formattedPrice}</p>
                <button>More Information</button>
            </section>
        </Link>
    );
}

export default Item;
