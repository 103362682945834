import {Link} from "gatsby"
import React, {useContext, useState} from "react"
import {Menu, ShoppingCart, X} from "react-feather";

import style from './header.module.css';
import {Button} from "@material-ui/core";
import SelectedProductContext from '../context/selectedProductsContext';
import {designSystem} from "./layout";

const Header = ({headerTextColour, backgroundColour, backgroundColourSmallScreen, showSmallHeading, smallHeadingColour}) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const selectedProductsContext = useContext(SelectedProductContext);
    const haveProductsBeenSelected = selectedProductsContext.selectedProducts.length > 0 ? style.shoppingCartFull : style.shoppingCartEmpty;

    return (
        <header
        >
            <div
                style={{
                    backgroundColor: backgroundColour
                }}
                className={style.headingContainer}
            >
                <div
                    className={style.heading}
                >
                    {showSmallHeading &&
                    <Link
                        to="/"
                    >
                        <span>Bodalla Lavender Shop</span>
                    </Link>}
                </div>
                <nav
                    className={style.largeScreenHeader}
                >
                    <Link
                        style={{
                            color: headerTextColour
                        }}
                        className={style.inactive}
                        activeClassName={style.active}
                        activeStyle={{
                            color: headerTextColour
                        }}
                        to="/"
                    >
                        <span>Home</span>
                    </Link>
                    <Link
                        to={'about'}
                        style={{
                            color: headerTextColour,
                        }}
                        className={style.inactive}
                        activeClassName={style.active}
                        activeStyle={{
                            color: headerTextColour
                        }}
                    >
                        <span>About</span>
                    </Link>
                    <Link
                        to={'contact'}
                        style={{
                            color: headerTextColour
                        }}
                        className={style.inactive}
                        activeClassName={style.active}
                        activeStyle={{
                            color: headerTextColour
                        }}
                    >
                        <span>Contact</span>
                    </Link>
                    <Link
                        to={'shop'}
                        style={{
                            color: headerTextColour
                        }}
                        className={style.inactive}
                        activeClassName={style.active}
                        activeStyle={{
                            color: headerTextColour
                        }}
                    >
                        <span>Shop</span>
                    </Link>
                    <Link
                        to={'cart'}
                        style={{
                            color: headerTextColour
                        }}
                        className={`${style.inactive} ${style.shoppingCartLink}`}
                        activeClassName={style.active}
                        activeStyle={{
                            color: headerTextColour
                        }}
                    >
                        <span>Cart</span>
                        <ShoppingCart
                            className={haveProductsBeenSelected}
                            color={headerTextColour}
                            size={10}
                        />
                    </Link>
                </nav>
            </div>
            <div
                className={style.smallScreenHeader}
                style={{
                    backgroundColor: backgroundColourSmallScreen ? backgroundColourSmallScreen : 'transparent',
                }}
            >
                <Link to='/'>
                    <h1
                        className={style.smallHeading}
                        style={{color: smallHeadingColour && smallHeadingColour}}
                    >Bodalla Lavender Shop
                    </h1>
                </Link>
                <Button
                    aria-haspopup='true'
                    aria-expanded={toggleMenu}
                    aria-controls='menu'
                    aria-label='Navigation'
                    onClick={() => setToggleMenu(!toggleMenu)}
                >
                    {toggleMenu ?
                        (
                            <X
                                style={{
                                    color: headerTextColour,
                                }}
                            />
                        ) : (
                            <Menu
                                style={{
                                    color: headerTextColour,
                                }}
                            />
                        )}

                </Button>
                <nav
                    aria-expanded={toggleMenu}
                    className={toggleMenu ? style.dropdown : style.dropdownClosed}
                    id="menu"
                    tabIndex="-1"
                >
                    <Link
                        to='/'>
                        Home
                    </Link>
                    <Link
                        to='about'>
                        About
                    </Link>
                    <Link
                        to='contact'>
                        Contact
                    </Link>
                    <Link
                        to='shop'>
                        Shop
                    </Link>
                    <Link
                        to='cart'>
                        Cart
                        {selectedProductsContext.selectedProducts.length > 0 &&
                        <ShoppingCart
                            color={designSystem.palette.mainPurple}
                        />}
                    </Link>
                </nav>
            </div>
        </header>
    )


};

export default Header;
