import React, {useState} from 'react';
import {graphql} from "gatsby"
import SEO from "../components/seo"
import {PRODUCT_TYPES} from "../utils/constants";
import Header from "../components/header";
import Layout, {designSystem} from "../components/layout";
import headerStyles from "../components/header.module.css";
import styles from "./shop.module.css";
import Item from "../components/item";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

const Shop = ({data}) => {
    const [filterTypes, setProductTypeToFilter] = useState([PRODUCT_TYPES.ALL]);

    const getProducts = () => {
        return JSON.parse(JSON.stringify(data.allStripeSku.nodes));
    }

    const convertProductTypes = allProducts => {
        return allProducts.map(product => {
            if (product.product.metadata.type != null) {
                product.product.metadata.type = product.product.metadata.type.split(/(\s+)/);
            }
            return product;
        })

    }

    const getProductsOfType = allProductsWithTypeArray => {
        if (filterTypes.indexOf(PRODUCT_TYPES.ALL) > -1 || filterTypes.length <= 0) {
            return allProductsWithTypeArray;
        }

        const productsWithType = allProductsWithTypeArray.filter(function (product) {
            return product.product.metadata.type != null;
        });

        return productsWithType.filter(product => {
            const productTypes = product.product.metadata.type;
            return doesProductContainType(productTypes, filterTypes);
        })
    }

    const doesProductContainType = (productTypes, filterTypes) => {
        return productTypes.some(type => filterTypes.includes(type));
    };

    const createAndConfigureProducts = () => {
        return convertProductTypes(getProducts());
    }

    const allProductsWithTypeArray = createAndConfigureProducts();

    const handleChange = event => {
        if (event.currentTarget.dataset.value === PRODUCT_TYPES.ALL) {
            setProductTypeToFilter([PRODUCT_TYPES.ALL]);
        } else {
            const {value} = event.target;
            const removeAll = value.filter(filterType => filterType !== PRODUCT_TYPES.ALL)
            setProductTypeToFilter([...removeAll]);
        }
    };

    const handleDelete = chipToDelete => () => {
        const productTypesToFilterBy = filterTypes.filter(product => product !== chipToDelete);
        productTypesToFilterBy.length <= 0 ? setProductTypeToFilter([PRODUCT_TYPES.ALL]) : setProductTypeToFilter(productTypesToFilterBy);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Layout>
            <SEO title="Shop"/>
            <div
                className={styles.allPage}
            >
                <div
                    className={`${headerStyles.header}`}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                <div
                    className={styles.content}
                >
                    <section
                        className={styles.productFilterSection}
                    >
                        <FormControl className={styles.formControl}>
                            <InputLabel id="product-filter-label">Filter Products By</InputLabel>
                            <Select
                                labelId="product-filter-label"
                                id="product-filter-chip"
                                multiple
                                value={filterTypes}
                                onChange={handleChange}
                                input={<Input id="select-multiple-chip"/>}
                                renderValue={selected => (
                                    <div className={styles.chips}>
                                        {selected.map(productType => (
                                            <Chip
                                                key={productType}
                                                label={productType}
                                                onDelete={handleDelete(productType)}
                                                variant="outlined"
                                                className={`${styles.chip} ${styles.menuItem}`}
                                                color={'primary'}
                                            />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {Object.values(PRODUCT_TYPES).map(productType => (
                                    <MenuItem key={productType} value={productType} className={styles.menuItem}>
                                        {productType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </section>
                    <ul
                        className={styles.productSection}>
                        {getProductsOfType(allProductsWithTypeArray).map(node => (
                            <li key={node.id}>
                                <Item
                                    itemName={node.attributes.name}
                                    itemPrice={node.price}
                                    imageSource={node.image}
                                    id={node.id}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Layout>
    )
};

export default Shop;

export const query = graphql`
        query ProductQuery {
            allStripeSku(filter: {product: {metadata: {product: {eq: "true"}}}}) {
                nodes {
                    id
                    image
                    price
                    attributes {
                        name
                    }
                    product {
                        metadata {
                            type
                        }
                    }
                    }
                }
               }
    `;